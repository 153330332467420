<template>
    <div id="goods-add-box">
        <h3>表单信息</h3>
        <el-form ref="form" :model="wzOne" label-width="80px" style="width:500px;">
            <el-form-item label="文章标题">
                <el-input v-model="wzOne.title"></el-input>
            </el-form-item>

            <el-form-item label="文章类目">
                <el-select v-model="wzOne.kind">
                    <el-option lable="痔疮" value="痔疮"></el-option>
                    <el-option lable="肛瘘" value="肛瘘"></el-option>
                    <el-option lable="肛裂" value="肛裂"></el-option>
                    <el-option lable="脱肛" value="脱肛"></el-option>
                    <el-option lable="大便出血" value="大便出血"></el-option>
                    <el-option lable="肛周脓肿" value="肛周脓肿"></el-option>
                    <el-option lable="便秘" value="便秘"></el-option>
                    <el-option lable="胃肠镜检查" value="胃肠镜检查"></el-option>
                    <el-option lable="肛门疾病" value="肛门疾病"></el-option>
                    <el-option lable="肠道疾病" value="肠道疾病"></el-option>
                    <el-option lable="胃肠疾病" value="胃肠疾病"></el-option>
                    <el-option lable="疾病检查" value="疾病检查"></el-option>
                    <el-option lable="其他" value="其他"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button style="margin: 150px 0" type="primary" @click="onSubmit">确定</el-button>
                <el-button style="margin: 150px 50px">取消</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { getForm, postJson } from '../tools/tool'

export default {
    name: "WenZhangOne",
    data() {
        return {
            wzOne: {
                title: "",
                kind: "",
            },
        }
    },
    created() { },
    mounted() {
    },
    methods: {
        onSubmit() {
            postJson("/wz/add", this.wzOne).then(res => {
                alert(res.msg)
            })

            console.log(this.wzOne)
        },
    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>